import React from "react";
import Logo from "../assets/bug-logo.png";
import "../styles/Navbar.css";

function Navbar() {
  return (
    <div className="navbar">
      <div className="leftSide">
        <img src={Logo} alt="Bug on Solana Logo" />
      </div>
      <div className="rightSide">
        <a
          className="navButton"
          href="https://www.dextools.io/app/en/solana/pair-explorer/46o6Q8TZgu8RQcHXVoVTRCKqztKaRyaPXTgYauTCgVoE?t=1710187802000"
          target="blank"
        >
          CHART
        </a>
      </div>
    </div>
  );
}

export default Navbar;
