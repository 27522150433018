import React from "react";
import BannerImage from "../assets/banner-image.png";
import HomeImage from "../assets/home-logo.png";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
      <div className="home-content">
        <div className="upper-content">
          <div className="left-side">
            <h2 className="title">BUG ON SOLANA</h2>
            <p className="subtitle">THE BUG OF THE SOLANA NETWORK</p>
            <a
              className="button"
              href="https://raydium.io/swap/?inputCurrency=sol&inputSymbol=BUG&outputCurrency=56sRswjgFGgBdVxgcK3ovo6uzsB9YCMNs59xuZDUL6Fi&inputAmount=0.073046919&outputAmount=171611.535114&fixed=in"
              target="blank"
            >
              BUY NOW
            </a>
          </div>
          <div className="right-side">
            <img className="image" src={HomeImage} alt="Bug on Solana Banner" />
          </div>
        </div>
        <div className="lower-content">
          <p className="contract">
            CONTRACT ADDRESS: 56sRswjgFGgBdVxgcK3ovo6uzsB9YCMNs59xuZDUL6Fi
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
