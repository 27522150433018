import React from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="left-side">
        <p> &copy; 2024 Bug on Solana</p>
      </div>
      <div className="right-side">
        <a className="social-link" href="https://t.me/BugOnSol" target="blank">
          <TelegramIcon />
        </a>
        <a
          className="social-link"
          href="https://twitter.com/BugOnSol"
          target="blank"
        >
          <XIcon />
        </a>
      </div>
    </div>
  );
}

export default Footer;
